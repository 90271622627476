import { api } from "../../../../shared/services/api/axios";

export class BookingRelatorioApi {
    static async postRelatorio(data){
        try{
            const response = await api.post('/booking/relatorio', data)
    
            return response.data
        }catch(error){
            console.log(error)
        }
    }
}