import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
`;

export const ContainerCollumContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 3rem;
`;

export const BoxForm = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  padding: 2rem;
  font-weight: bold;
  gap: 1rem;
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
`;
export const BoxRow = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
`;

export const BoxFooter = styled.footer`
  display: flex;
  flex-direction: line;
  gap: 1rem;
  width: 100%;
  justify-content: end;
`;

export const Button = styled.button`
  color: white;
  background-color: #17b51c;
  border: none;
  font-weight: bold;
  border-radius: 3px;
  padding: 0.5rem;
`;

export const ButtonAmarelo = styled.button`
  color: white;
  background-color: #f7b500;
  border: none;
  font-weight: bold;
  border-radius: 3px;
  padding: 0.5rem;
`;

export const P = styled.p`
  font-size: 2rem;
  color: #464646;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #464646;
  font-weight: bold;
  text-align: center;
`;

export const AlinharDireita = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  `;

export const AlinharEsquerda = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  tr:nth-child(even) {
    background-color: #f2f2f2;
  } 
  th {
    background-color: #9bd3ae;
    padding: 0.5rem;
  }
`;