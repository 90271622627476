import { Button, Container, ContainerCollumContent, ContainerRow, BoxForm, BoxContent, Title, Table, AlinharDireita, AlinharEsquerda } from './styles';
import axios from 'axios';
import { useState, useEffect } from 'react';

const api = axios.create({
    baseURL: 'https://api.mercado.jit.technology',
});




const Registro = () => {
    const [registros, setRegistros] = useState([]);
    const [quantidade, setQuantidade] = useState(0);

    useEffect(() => {
        api.get('/requisicoes')
            .then(res => {
                setRegistros(res.data);
            })
            .catch(err => {
                console.error(err);
            });
        api.get('/requisicoes/quantidade')
            .then(res => {
                setQuantidade(res.data.count);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);


    return (
        <Container>
            <ContainerRow>
                <ContainerCollumContent>
                    <BoxForm>
                        <Title>Registro de Consultas</Title>

                        <AlinharEsquerda>
                            <p>Quantidade de consultas: {quantidade}</p>
                        </AlinharEsquerda>
                        <AlinharDireita>
                            <Button onClick={() => window.location.href = '/'}>Estudo de Mercado</Button>
                        </AlinharDireita>

                        <BoxContent>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Local</th>
                                        <th>Email</th>
                                        <th>Tipo Locação</th>
                                        <th>Quantidade de dias</th>
                                        <th>Valor Mínimo</th>
                                        <th>Valor Máximo</th>
                                        <th>Quantidade Adultos</th>
                                        <th>Quantidade Crianças</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {registros.map(registro => (
                                        <tr>
                                            <td>{new Date(registro.data_registro).toLocaleDateString()}</td>
                                            <td>{registro.local_registro}</td>
                                            <td>{registro.email_registro}</td>
                                            <td>{registro.tipo_locacao_registro}</td>
                                            <td>{registro.quantidade_dias_registro}</td>
                                            <td>{registro.valor_minimo_registro}</td>
                                            <td>{registro.valor_maximo_registro}</td>
                                            <td>{registro.quantidade_adultos_registro}</td>
                                            <td>{registro.quantidade_criancas_registro}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </BoxContent>
                    </BoxForm>
                </ContainerCollumContent>
            </ContainerRow>
        </Container>
    );
};

export default Registro;
