import { BookingRelatorioApi } from "./api/bookingRelatorioApi";

export class BookingRelatorioService {
    static async postRelatorio(data){
        try {
            const response = BookingRelatorioApi.postRelatorio(data)
            return response
        } catch (error) {
            console.log(error)
        }
    }
}