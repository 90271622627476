import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { FormControlLabel, FormLabel, InputLabel, NativeSelect, Radio, RadioGroup } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
//map
import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { BookingRelatorioService } from './service/bookingRelatorioService';
import { FormControl } from '@mui/base';
// biblioteca para trabalhar com datas
/* eslint-disable no-unused-vars */
import { format, addDays, parse, differenceInDays } from 'date-fns';
//importa a lojo da pasta public

const MainScreen = () => {

    // funcoes do mapa
    const [position, setPosition] = React.useState(null,null);
    function LocationMarker() {
        const map = useMapEvents({
          click(e) {
            if(radioValue === 'mapa'){
                setLatitude(e.latlng.lat);
                setLongitude(e.latlng.lng);
                setPosition(e.latlng);
            }
          },
        });
    
        useEffect(() => {
          const handleLocationFound = (e) => {
            if(position === null){
                const { lat, lng } = e.latlng;
                setLatitude(lat);
                setLongitude(lng);
                setPosition({ lat, lng });
                if(userPosition === null){
                    setUserPosition({ lat, lng });
                }
                map.flyTo([lat, lng], map.getZoom());
            }else {
                const { lat, lng } = position;
                map.flyTo([lat, lng], map.getZoom());
            }
          };
    
          map.locate();
          map.on('locationfound', handleLocationFound);
    
          return () => {
            map.off('locationfound', handleLocationFound);
          };
        }, [map]);

        const customIcon = new L.Icon({
            iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
            iconSize: [32, 32], // Tamanho do ícone
            iconAnchor: [16, 32], // Ponto de ancoragem do ícone
            popupAnchor: [0, -32], // Ponto de ancoragem do popup
          });
    
        return position === null ? null : (
          <Marker position={position} icon={customIcon}>
            <Popup>You are here</Popup>
          </Marker>
        );
      }// fim funcoes do mapa

    const [ss, setSS] = React.useState("");
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [email, setEmail] = React.useState("");
    const [tipoLocacao, setTipoLocacao] = React.useState("hotel");
    const [qntdAdultos, setQntdAdultos] = React.useState(2);
    const [qntdCriancas, setQntdCriancas] = React.useState(0);
    const [mostrarAlert, setMostrarAlert] = React.useState('none');
    const [mostrarAlertErro, setMostrarAlertErro] = React.useState('none');
    const [valorMinimo, setValorMinimo] = React.useState("");
    const [valorMaximo, setValorMaximo] = React.useState("");
    const [radioValue, setRadioValue] = React.useState("atual");
    const [userPosition, setUserPosition] = React.useState(null);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState('');

    const loadCurrentDate = () => {
        // Define a data inicial como a data atual
        const currentDate = new Date();
        setStartDate(format(currentDate, 'yyyy-MM-dd'));
    
        // Define a data final como 7 dias a partir da data atual
        const nextWeek = addDays(currentDate, 7);
        setEndDate(format(nextWeek, 'yyyy-MM-dd'));
    }

    useEffect(() => {
        loadCurrentDate();
      }, []);

    const handleClickSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const serverResponse = await fetch('https://api.mercado.jit.technology/');
            const data = await serverResponse.json();
    
            if (data.msg === 'ok') {
                const formData = {
                    ss: ss,
                    latitude: radioValue === 'local' ? null : `${latitude}`,
                    longitude: radioValue === 'local' ? null : `${longitude}`,
                    email: email,
                    tipoLocacao: tipoLocacao,
                    qntdAdultos: qntdAdultos,
                    qntdCriancas: qntdCriancas,
                    dataInicial: startDate,
                    dataFinal: endDate,
                    valorMinimo: valorMinimo,
                    valorMaximo: valorMaximo
                };
    
                // Limpar campos após o envio do formulário
                setSS("");
                setLatitude(0);
                setLongitude(0);
                setEmail("");
                setTipoLocacao("hotel");
                setQntdAdultos(2);
                setQntdCriancas(0);
                setValorMinimo("");
                setValorMaximo("");
                setRadioValue("atual");
                loadCurrentDate();
                setPosition(userPosition);
            
                console.log("fazendo consulta...")
                const response = await BookingRelatorioService.postRelatorio(formData);
    
                console.log("email enviado, chegará em breve...! -> ", response)
                setMostrarAlert('flex');
                setTimeout(() => {
                    setMostrarAlert('none');
                }, 5000);
            } else {
                console.log('Erro ao enviar o formulário');
                setMostrarAlertErro('flex');
                setTimeout(() => {
                    setMostrarAlertErro('none');
                }, 5000);
            }
        } catch (error) {
            console.log('Erro ao enviar o formulário');
            setMostrarAlertErro('flex');
            setTimeout(() => {
                setMostrarAlertErro('none');
            }, 5000);
        }
    }
    

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value)
        // latitude e londigitude do eldorado: -3.721917844129945, -38.51210280392331
        // latitude e longitude do center 1: -3.729022889553065, -38.518956601180044
        switch (event.target.value) {
            case 'eldorado':
                setPosition({lat: -3.721917844129945, lng: -38.51210280392331});
                setLatitude(-3.721917844129945);
                setLongitude(-38.51210280392331);
                setTipoLocacao('hotel');
                break;
            case 'center1':
                setPosition({lat: -3.729022889553065, lng: -38.518956601180044});
                setLatitude(-3.729022889553065);
                setLongitude(-38.518956601180044);
                setTipoLocacao('hotel');
                break;
            case 'flat-praia-iracema':
                setPosition({lat: -3.7212227798388233, lng: -38.510881673138925});
                setLatitude(-3.7212227798388233);
                setLongitude(-38.510881673138925);
                setTipoLocacao('apartamento');
                break;
            case 'flat-meireles':
                setPosition({lat: -3.7267629146926344, lng: -38.498101005283395});
                setLatitude(-3.7267629146926344);
                setLongitude(-38.498101005283395);
                setTipoLocacao('apartamento');
                break;
            case 'atual':
                setPosition(userPosition);
                setLatitude(userPosition.lat);
                setLongitude(userPosition.lng);
                break;
            default:
                break;
        }
    }
    
    // Funções para atualizar os estados de data inicial e final
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const calculateDifferenceDays = (dataInicio, dataFim) => {
        // Parse das datas
        const startDate = parse(dataInicio, 'yyyy-MM-dd', new Date());
        const endDate = parse(dataFim, 'yyyy-MM-dd', new Date());
        
        // Calcula a diferença em dias
        const diferencaDias = differenceInDays(endDate, startDate);
        
        return diferencaDias;
    };

    return (
        <>  
            <Alert severity="success" variant='filled' sx={{ display: mostrarAlert, position: 'absolute', top: 0, right: 0, margin: 2 }}>
                <AlertTitle>Aguarde</AlertTitle>
                Em alguns momentos você receberá um e-mail com o relatório.
            </Alert>
            <Alert severity="error" variant='filled' sx={{ display: mostrarAlertErro, position: 'absolute', top: 0, right: 0, margin: 2 }}>
                <AlertTitle>Erro</AlertTitle>
                No momento estamos fora do ar, tente novamente mais tarde.
            </Alert>

            <a href='https://jit.technology/'>
                <img src='JIT-LOGO.png' height={60} alt='Logo' style={{ position: 'absolute', bottom: 0, right: 0, margin: 2 }} />
            </a >
            <div className='content-wrapper' style={
                {
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems:'center',
                    flexDirection: 'row',
                    maxHeight: '100vh'
                }
                }>
                <div> 
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 30,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',

                            }}
                        >

                            <Typography component="h1"
                                sx={{
                                    color: '#3f51b5',
                                    fontWeight: 'bold',
                                    fontSize: '30px',
                                    marginBottom: 5,
                                }}
                            >
                                Estudo de Mercado
                            </Typography>
                            <Box component="form"
                                onSubmit={handleClickSubmit}
                            >   
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Escolha uma opção</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={radioValue}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="eldorado" control={<Radio />} label="Eldorado" />
                                    <FormControlLabel value="center1" control={<Radio />} label="Center 1" />
                                    <FormControlLabel value="flat-praia-iracema" control={<Radio />} label="Flat Praia de Iracema Vista mar" />
                                    <FormControlLabel value="flat-meireles" control={<Radio />} label="Flat Meireles Vista Mar" />
                                    <FormControlLabel value="local" control={<Radio />} label="Digitar local" />
                                    <FormControlLabel value="atual" control={<Radio />} label="Usar sua localização atual" />
                                    <FormControlLabel value="mapa" control={<Radio />} label="Escolher no mapa" />
                                </RadioGroup>
                            </FormControl>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
            
                                            fullWidth
                                            id="ss"
                                            label="Local"
                                            name="ss"
                                            value={ss}
                                            onChange={(e) => setSS(e.target.value)}
                                            sx={{ display: radioValue === 'local' ? 'block' : 'none' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Tipo de alocação
                                        </InputLabel>
                                        <NativeSelect
                                            fullWidth
                                            value={tipoLocacao}
                                            onChange={(e) => setTipoLocacao(e.target.value)}
                                        >
                                            <option value={"hotel"}>Hotel</option>
                                            <option value={"apartamento"}>Apartamento</option>
                                        </NativeSelect>
                                    </Grid>
                                    <Grid item xs={12} sm={6}></Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div>
                                            <label htmlFor="dataInicial">Data inicial:</label>
                                            <input
                                                // disabled={true}
                                                required
                                                type="date"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                min={format(new Date(), 'yyyy-MM-dd')}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div>
                                            <label htmlFor="dataFinal">Data final:</label>
                                            <input
                                                required
                                                type="date"
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
                                                // min={format(new Date(), 'yyyy-MM-dd')}
                                            />
                                        </div>
                                    
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="valorMinimo"
                                            label="Valor Mínimo"
                                            type="float"
                                            name="valorMinimo"
                                            value={valorMinimo}
                                            onChange={(e) => setValorMinimo(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="valorMaximo"
                                            label="Valor Máximo"
                                            type="float"
                                            name="valorMaximo"
                                            value={valorMaximo}
                                            onChange={(e) => setValorMaximo(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="qntdAdultos"
                                            label="Adultos"
                                            type="number"
                                            name="qntdAdultos"
                                            value={qntdAdultos}
                                            onChange={(e) => setQntdAdultos(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="qntdCriancas"
                                            label="Crianças"
                                            type="number"
                                            name="qntdCriancas"
                                            value={qntdCriancas}
                                            onChange={(e) => setQntdCriancas(e.target.value)}
                                        />
                                    </Grid>

                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Enviar
                                </Button>
                            </Box>
                        </Box>
                    </Container >
                </div>
                <MapContainer
                    //Latitude e Longitude de Fortaleza
                    //-3.7333322339307298, -38.52894105066056
                    center={{ lat: -3.7333322339307298, lng: -38.52894105066056 }}
                    zoom={16}
                    style={{marginTop:'60px', height: '500px', width: '60%'}}
                    scrollWheelZoom={true}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarker />
                </MapContainer>
            </div>
        </>
    );
}

export default MainScreen;
